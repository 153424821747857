import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../../style/Carousel.css';
import { Box, Button } from '@mui/material';
import Header from '../../../components/general/Header.tsx';

const Carousel = ({ images, defaultSlide, onRecord }) => {
  const [currentSlide, setCurrentSlide] = useState(defaultSlide);

  const handleRecord = (index) => {
    onRecord(index);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Number of images to display
    slidesToScroll: 1,
    initialSlide: defaultSlide,
    centerMode: true,
    centerPadding: '50px',
    afterChange: (index) => setCurrentSlide(index)
  };

  return (
    <>
      <Header title={'How Are You Feeling Today?'} />

      <div style={{ borderRadius: '15px', position: 'absolute', top: '0', bottom: '0', margin:'auto 0', height: 'fit-content', width: '100%', padding: '0 5px' }}>
        <Slider {...settings}>
            {images.map((image, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={image} alt={`Slide ${index + 1}`} />
                </div>
            ))}
        </Slider>
        
        <Box sx={{textAlign: 'center', height: '10px', width: '100%'}}>
            <Button variant="contained" style={{width: '60%', marginTop: '20px'}} onClick={() => {handleRecord(currentSlide)}}>Record Mood</Button>
        </Box>
      </div>
    </>
  );
};

export default Carousel;