import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import CustomPieChart from '../../../components/visualisation/Pie Chart';
import LinearProgressWithLabel from '../../../components/visualisation/LinearProgressWithPercent';
import dayjs from 'dayjs';

const SessionEntry = ({session}) => {

    const [state, setState] = useState(false);
    const navigate = useNavigate();

    const handleParentClick = () => {
        navigate("/diary-entry", { state: { session }});
    };

    const handleButtonClick = (event) => {
        event.stopPropagation();
        setState(!state);
    };

    const getFormattedDuration = (start, end) => {
        const durationInMins = Math.floor((end - start) / 60000);
        const hours = Math.floor(durationInMins / 60);
        const mins = durationInMins % 60;
        return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`
    };

    const formattedDuration = getFormattedDuration(new Date(session.session_date), new Date(session.end_time));

    const moodIcons = [
        <SentimentVeryDissatisfiedIcon style={{height:'1.6em'}} />,
        <SentimentDissatisfiedIcon style={{height:'1.6em'}} />,
        <SentimentNeutralIcon style={{height:'1.6em'}} />,
        <SentimentSatisfiedIcon style={{height:'1.6em'}} />,
        <SentimentVerySatisfiedIcon style={{height:'1.6em'}} />
    ]

    return (
        <div className='entry' style={{cursor: 'pointer', position: 'relative', border: '2px solid var(--site-light-background-color)', borderRadius: '10px', padding: '5px', margin: '5px 0'}} onClick={handleParentClick} >
        <h3 style={{ fontWeight: 'bold'}}>
            {dayjs(session.session_date).format("DD/MM/YY") + ' - ' + session.discipline}
            {session.status === 'active' && <span style={{
                    display: 'inline-block',
                    width: '10px',
                    height: '10px',
                    marginLeft: '10px',
                    borderRadius: '50%',
                    backgroundColor: '#008000',
            }}></span>}
        </h3>

        {state && <>
            {session.session_aims?.length > 1 && <>
                <p style={{ fontWeight: '600'}}>Aims</p>
                <p>{session.session_aims}</p>
            </>}
            {session.status === 'active' &&
                <p>Start time: {dayjs(session.session_date).format("HH:mm A")}</p>
            }
            {session.status === 'active' && 
                <p>Initial Mood: <span style={{ verticalAlign: 'text-top' }}> {moodIcons[session.start_mood]}</span></p>
            }
            {(session.status === 'closed') && <>
            
                {session.end_time !== null &&
                    <p>Session length: {formattedDuration}</p>
                }
                <p>
                    Initial Mood: 
                    <span style={{ verticalAlign: 'text-top' }}> {moodIcons[session.start_mood]}</span>
                    , End mood: 
                    <span style={{ verticalAlign: 'text-top' }}> {moodIcons[session.end_mood]}</span>
                </p>
                <p>Total efforts:  {session.total_exercises}</p>
                <p>Total skills:  {session.total_skills}</p>
                <p>Total difficulty:  {session.total_difficulty}</p>

                {session.exercise_success_rate > 0.0 && <>
                    <p>Exercise completion:  {session.exercise_success_rate}%</p>
                    <LinearProgressWithLabel progress={session.exercise_success_rate} />
                </>}
                {session.skills_success_rate > 0.0 && <>
                    <p>Skills completion:  {session.skills_success_rate}%</p>
                    <LinearProgressWithLabel progress={session.skills_success_rate} />
                </>}
                {session.average_tof > 0.0 &&
                    <p>Average ToF:  {session.average_tof}s</p>
                }
                {session.average_stopwatch > 0.0 &&
                    <p>Average Stopwatch ToF:  {session.average_stopwatch}s</p>
                }
                {session.hydration > 0 &&
                    <p>Water consumption: {session.hydration}ml</p>
                }
                {session.session_feedback?.length > 1 && <>
                    <p style={{ fontWeight: '600'}}>Feedback</p>
                    <p>{session.session_feedback}</p>
                </>}
                {session.session_reflection?.length > 1 && <>
                    <p style={{ fontWeight: '600'}}>Reflection</p>
                    <p>{session.session_reflection}</p>
                </>}
                {(session.total_routines > 0 || session.total_drills > 0 || session.total_skill_attempts > 0) && 
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px'}}>
                        <CustomPieChart data={[
                            { name: "Routines", value: session.total_routines },
                            { name: "Drills", value: session.total_drills },
                            { name: "Skills", value: session.total_skill_attempts },
                            { name: "Other", value: session.total_exercises - (session.total_routines + session.total_drills + session.total_skill_attempts) },
                        ]} />
                    </div>
                }
                
            </>}
        </>}

        <Button 
            style={{
            fontSize: '1.4rem', 
            color: '#000', 
            backgroundColor: 'transparent',
            }} 
            onClick={handleButtonClick}
        >
            {!state ? 'Show summary' : 'Hide summary'}
        </Button>
    </div>
    )
};

export default SessionEntry;