import React from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const COLORS = ["#83FFE7", "#545454", "#36A2EB", "#FFCE56"];

const CustomPieChart = ({ data }) => {
    const renderLegend = (value) => {
        return <span style={{ color: "#545454", fontSize: "16px" }}>{value}</span>;
    };

    return (<PieChart width={250} height={250}>
        <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={100}
            fill="#8884d8"
        >
            {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
        </Pie>
        <Tooltip 
            contentStyle={{
                fontSize: "14px", // Tooltip text size
                color: "#000",
            }} 
        />
        <Legend
            formatter={renderLegend} // Apply custom formatter
            wrapperStyle={{
                fontSize: "16px",
            }}
        />
        <text
            x="50%"
            y="40%"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
                fontSize: "18px",
                fontWeight: "bold",
                fill: "#545454",
            }}
        >
            Session Split
        </text>
    </PieChart>)
};

export default CustomPieChart;
