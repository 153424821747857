import axios from "axios";
import { Button } from "@mui/material";
import Header from "../components/general/Header.tsx";

const NoSubscription = (user) => {

    const createCheckout = async () => {
        const response = await axios.post(
            `${process.env.REACT_APP_API_HOST}/payments/checkout-session`
          );
          const { subscriptionStatus, checkoutSessionUrl } = response.data;
  
          if (subscriptionStatus === 'active') {
            console.log("active already")
          } else {
            // Redirect to Stripe Checkout
            window.location.href = checkoutSessionUrl;
          }
    }

    return (<div style={{margin: 'auto', textAlign: 'center'}}>
        <Header title={'No Active Subscription'}></Header>
        <div style={{position: 'fixed', top: '0', left: '0', right: 0, height: 'calc(100% - 56px)'}}></div>
        <div style={{backgroundColor: '#fff', border: "2px solid #545454", borderRadius: '15px', position: 'absolute', top: '0', bottom: '10%', margin:'auto 0', height: 'fit-content', width: '100%', zIndex: '1000', padding: '10px 20px'}}>
            <h2>No Subscription</h2>
            <p style={{marginTop: '10px', fontSize: '1.6rem', fontWeight: '600'}}>You must have an active subscription to access the app.</p>
            <p style={{marginTop: '10px', fontSize: '1.6rem', fontWeight: '600'}}>If you believe you already have an active subscription, please contact support@digitaltrainingdiary.com</p>

            <Button size="large" style={{backgroundColor: '#83FFE7', color: '#545454', marginTop: '10px'}} onClick={createCheckout}>Subscribe</Button>
            
        </div>
    </div>)
};

export default NoSubscription;