import { useState } from "react";
import { ToggleButton, ToggleButtonGroup, TextField, Button, Autocomplete } from "@mui/material";
import filterSkillsList from "../../../components/utils/FilterSkillsList";

const AddRoutine = ({type, routineHolder, skillsList, names, handleNewEntry, handleCancel, gender}) => {
    const [routine, setRoutine] = useState(routineHolder);
    const [routineSkills, setRoutineSkills] = useState(routineHolder.routine.skills.map(skillId => skillsList.filter(skill => skill.id === skillId)[0]));

    const triffBonusCount = (gender === 'male') ? 5 : 2;

    const handleFieldChange = (e, fieldName) => {
        setRoutine({
            ...routine,
            [fieldName]: e.target.value
        })
    };

    const handleSkillChange = (e, index) => {
        let newRoutine = routine.routine;
        let newRoutineSkills = routineSkills;

        const selectedSkill = e.target.innerText;

        if (selectedSkill !== undefined && selectedSkill.length !== 0) {
            let newSkillId = skillsList.find(value => 
                (value.name + ' (' + value.fig + value.shape + ')') === selectedSkill
            ).id;

            newRoutine.skills[index] = newSkillId;
            newRoutineSkills[index] = skillsList.filter(skill => skill.id === newSkillId)[0];

            setRoutineSkills(newRoutineSkills);
            let totalTriffs = newRoutineSkills.filter(skill => skill.somersaults >= 3).length;

            let new_diff = parseFloat(newRoutine.skills.reduce((total, current) => total + skillsList.filter((value) => value.id === current)[0].difficulty, 0).toFixed(1));
            if (totalTriffs > triffBonusCount) {
                new_diff += 0.3 * (totalTriffs - triffBonusCount);
            }

            newRoutine = {
                ...newRoutine,
                difficulty: parseFloat(new_diff.toFixed(1))
            }

            setRoutine({
                ...routine,
                routine: newRoutine
            });
        }
    };

    const handleSubmit = () => {
        let newRoutine = routine.routine;

        const routine_id = routine.routine.skills.join('-');
        newRoutine = {
            ...newRoutine,
            routine_id: routine_id
        };

        handleNewEntry('trampoline', {
            ...routine,
            routine: newRoutine});
    };

    return (
        <form className="new-entry" style={{padding: '5px'}}>
            
            <TextField fullWidth style={{ marginTop: "5px" }}
                type="text"
                id="routine-name"
                label="Routine Name"
                variant="outlined"
                value={routine.name}
                onChange={(e) => handleFieldChange(e, 'name')}
            />

            {type === 'editing-tri' && <>
                <ToggleButtonGroup
                    fullWidth
                    style={{margin: '10px 0 5px'}}
                    color="primary"
                    value={routine.status}
                    exclusive
                    onChange={(e) => handleFieldChange(e, 'status')}
                    aria-label="active status"
                >
                    <ToggleButton color="success" value={'active'} aria-label="active">Active</ToggleButton>
                    <ToggleButton color="error" value={'inactive'} aria-label="inactive">Inactive</ToggleButton>
                </ToggleButtonGroup>
            </>}
        
            {routine.routine.skills.map((skill, index) => 
                <Autocomplete 
                    key={index} 
                    style={{marginTop: '10px'}} 
                    options={filterSkillsList(skillsList, routine.routine.skills, index, 10)} 
                    getOptionLabel={(option) => (option.name + ' (' + option.fig + option.shape + ')')} 
                    isOptionEqualToValue={/*(option, value) => option.id === value.id*/ () => true} //hardcoded to true temporarily
                    value={skillsList.filter((skill) => skill.id === routine.routine.skills[index])[0]}
                    onChange={(e) => {handleSkillChange(e, index)}}
                    renderInput={(params) => (
                        <TextField {...params} label={"Search or select a skill"} variant="outlined" />
                    )}
                />
            )}

            <p>{'Difficulty: ' + routine.routine.difficulty}</p>

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "5px 5px 5px"}}
                    disabled={routine.name === '' || (type === 'entering-tri' && names.includes(routine.name.toLowerCase()))}
                    onClick={handleSubmit}
                    variant="contained"
                >
                    {type === 'entering-tri' ? 'Add routine' : 'Update routine'}
                </Button>

                <Button
                    style={{margin: "5px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>
        </form>
    )

}

export default AddRoutine;