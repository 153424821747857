import { useState } from "react";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const SynchroResultsEntry = ({result, setEditing, remove}) => {

    const [state, setState] = useState(false);

    return (
        <div className='entry' style={{position: 'relative', border: '2px solid var(--site-light-background-color)', borderRadius: '10px', padding: '5px', margin: '5px 0'}}>
            <h3 style={{ padding: '0 40px' }}>{result.event}</h3>
            
            <IconButton style={{position: 'absolute', top: '6px', right: '5px', padding: '0', fontSize: '20px'}} aria-label="delete" size="large" alt='delete' onClick={() => (remove(result.id))}>
                <DeleteIcon color="black" fontSize="inherit" />
            </IconButton>
            <IconButton style={{position: 'absolute', top: '6px', right: '28px', padding: '0', fontSize: '20px'}} aria-label="edit" size="large" alt='edit' onClick={() => setEditing('synchro', result)}>
                <EditIcon fontSize="inherit" />
            </IconButton>
            
            <p>{'Total Score: ' + result.total_score}</p>

            {state && <>
                <p>Execution: {result.execution}</p>
                <p>Difficulty: {result.difficulty}</p>
                <p>Desynch: {result.desynch}</p>
                <p>HD: {result.hd}</p>
                <p>Penalty: {result.penalty}</p>
                {(result.comment !== null && result.comment.length > 0) && <>
                    <p style={{ fontWeight: '600' }}>Comments:</p>
                    <p>{result.comment}</p>
                </>}
            </>}
            
            <Button style={{fontSize: '14px', color: '#000', backgroundColor: 'transparent'}} onClick={() => setState(!state)}>
                {!state ? 'Show more' : 'Show less'}
            </Button>

        </div>
    )
}

export default SynchroResultsEntry;