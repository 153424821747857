import { useEffect, useState } from "react";
import axios from "axios";
import { FormControl, InputLabel, Select, MenuItem, ToggleButton, ToggleButtonGroup, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import ErrorMessage from "../../../components/ErrorMessage.js";

const RoutineInsights = ({ userId, discipline, stats, userRoutines }) => {
    
    const [selectedObjective, setSelectedObjective] = useState(null);
    const [ddOption, setDdOption] = useState(userRoutines[0]?.routine?.routine_id || '');
    const [timePeriod, setTimePeriod] = useState('all');
    const [routineStats, setRoutineStats] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState("attempts");
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (selectedObjective) {
            if (timePeriod === 'all') {
                setRoutineStats([stats.find(stat => stat.id === selectedObjective.id)]);
            } else {
                axios.get(`${process.env.REACT_APP_API_HOST}/api/insights/routines/${discipline}/${userId}/${selectedObjective?.id}?period=${timePeriod}`)
                .then(response => {
                    const data = response.data;
                    if (data !== null && data.length > 0) {
                        setRoutineStats(data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data: ', error);
                    setIsError(true);
                });
            }
        }
    }, [userId, discipline, timePeriod, selectedObjective, stats])

    const handleObjectiveChange = (event) => {
        setDdOption(event.target.value)
        const selectedId = event.target.value;
        const objective = stats.find(obj => obj.id === selectedId);
        setSelectedObjective(objective || null);
    };

    const attributes = [
        {header: '# Attempts', field: 'attempts'},
        {header: 'Completion Rate', field: 'completion_rate'},
        {header: 'Skill Success Rate', field: 'skill_completion_rate'},
        {header: 'Avg. Execution', field: 'average_ex'},
        ...(discipline === 'trampoline' ? [
            {header: 'Avg. ToF', field: 'average_tof'},
            {header: 'Avg. Stopwatch', field: 'average_stopwatch'},
            {header: 'Avg. HD', field: 'average_hd'},
        ] : []),
        ...(discipline === 'dmt' ? [
            {header: 'Avg. ToF', field: 'average_tof'},
            {header: 'Avg. Stopwatch', field: 'average_stopwatch'},
            {header: 'Avg. Landing Pen', field: 'average_landing'},
        ] : []),
        ...(discipline === 'tumbling' ? [
            {header: 'Avg. Landing Pen', field: 'average_landing'},
        ] : [])
    ];

    const routineDates = [...new Set(routineStats?.map(routine => routine.period))];

    const columns = [
        { field: 'stat', headerName: 'Statistic', width: 120 },
        ...routineDates?.map(date => ({ 
            field: date, 
            headerName: date, 
            width: 120,
            renderCell: params => {
                if (params.id === 'completion_rate') {
                    return `${params.value || 0}%`;
                }
                else if (params.row.id === 'skill_completion_rate') {
                    return `${params.value || 0}%`;
                }
                else if (params.row.id === 'average_landing') {
                    return params.value === 999 ? 0 : params.value;
                }
                else if (params.row.id === 'average_tof' || params.row.id === 'average_stopwatch') {
                    return params.value && `${params.value}s`;
                }
                return params.value;
            }
         }))
    ];

    const rows = attributes.map(attribute => ({
        id: attribute.field,
        stat: attribute.header,
        ...routineDates?.reduce((acc, date) => {
            const routine = routineStats?.find(s => s.period === date);
            acc[date] = routine ? routine[attribute.field] : 'N/A';
            return acc;
        }, {}),
    }));

    const graphData = routineDates.map(date => ({
        name: date,
        value: rows.find(row => row.id === selectedAttribute)[date],
    })).filter(attr => attr.value !== 0);

      
    if (isError) return <ErrorMessage handleConfirm={() => setIsError(false)} />;

    return (
        <>
            <form className="new-entry">
                <FormControl fullWidth style={{ margin: "10px 0" }}>
                    <InputLabel id="objective-label">Routine</InputLabel>
                    <Select
                        labelId="objective-label"
                        label="Routine"
                        variant="outlined"
                        value={ddOption || ''}
                        onChange={handleObjectiveChange}
                    >
                        {userRoutines.map((obj, index) => (
                            <MenuItem key={index} value={obj.routine.routine_id}>
                                {obj.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <ToggleButtonGroup
                    fullWidth
                    style={{marginBottom: '10px'}}
                    color="primary"
                    value={timePeriod}
                    exclusive
                    onChange={e => setTimePeriod(e.target.value)}
                    aria-label="By week or month"
                >
                    <ToggleButton color="primary" value={'all'} aria-label="all">All Time</ToggleButton>
                    <ToggleButton color="primary" value={'month'} aria-label="month">By Month</ToggleButton>
                    <ToggleButton color="primary" value={'week'} aria-label="week">By Week</ToggleButton>
                </ToggleButtonGroup>

                {/* Display Stats Only If selectedObjective Exists */}
                {selectedObjective ? (
                    <>                         
                        <Box sx={{ height: '380px', width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                hideFooter={true}
                            />
                        </Box>
                        {routineStats.length > 1 && <>
                            <FormControl fullWidth style={{ margin: "16px 0" }}>
                                <InputLabel>Graph Attribute</InputLabel>
                                <Select
                                    value={selectedAttribute}
                                    onChange={e => setSelectedAttribute(e.target.value)}
                                >
                                {rows.map(row => (
                                    <MenuItem key={row.id} value={row.id}>
                                        {row.stat}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>

                            <LineChart
                                width={330}
                                height={400}
                                data={graphData}
                                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis 
                                    dataKey="name"
                                    tick={{ fontSize: 14 }}
                                    reversed={true}
                                />
                                <YAxis 
                                    tick={{ fontSize: 14 }}
                                />
                                <Tooltip 
                                    contentStyle={{ fontSize: 14 }}
                                    labelStyle={{ fontSize: 16 }}
                                    itemStyle={{ fontSize: 16 }}
                                />
                                {/* <Legend 
                                    wrapperStyle={{ fontSize: '16px' }}
                                /> */}
                                <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                        </>}
                    </>
                ) : (
                    <p style={{color: 'var(--site-light-text-color)'}}>No statistics available for the selected objective.</p>
                )}
            </form>
        </>
    );
};

export default RoutineInsights;
