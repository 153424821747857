import { useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import '../../../style/Moodscreen.css'

const MoodConf = ({moodVal, handleClose, handleConfirm}) => {

    const allowBegin = moodVal >= 2 ? true : false;
    const navigate = useNavigate();

    const close = () => {
        handleClose();
    };

    let source;
    let modalText;

    switch (moodVal) {
        case 0:
            source = "../img/mood_s/very_bad_w.png";
            modalText = "Before you start training, it's important to speak to your coach to make a plan to manage your mood.";
            break;
        case 1:
            source = "../img/mood_s/bad_w.png";
            modalText = "Before you start training, it's important to speak to your coach to make a plan to manage your mood.";
            break;
        case 2:
            source = "../img/mood_s/neutral_w.png";
            modalText = "Let your coach know you're feeling a bit meh. Maybe a great training session will make you smile!";
            break;
        case 3:
            source = "../img/mood_s/good_w.png";
            modalText = "It's great you're feeling happy and ready to go. Have a great session!";
            break;
        case 4:
            source = "../img/mood_s/very_good_w.png";
            modalText = "It's great you're feeling happy and ready to go. Have a great session!";
            break;
        default:
            return;
    }

    return (<>
        <IconButton style={{position: 'absolute', top: '35px', right: '0', padding: '0', fontSize: "40px", color: "var(--site-light-text-color)"}} onClick={close} aria-label="back" size="large" alt='close' >
            <CloseIcon fontSize="inherit" />
        </IconButton>

        <div className="form-wrapper">
            <div className="mood-image">
                <img src={source} id="selected-image" alt='mood' />
            </div>
            <p id="modalContent" style={{color: 'var(--site-light-text-color)'}}>{modalText}</p>
            { allowBegin ? <>
                <Box sx={{textAlign: 'center', height: '33px', width: '100%'}}>
                    <Button variant="contained" style={{width: '60%', fontSize: '12px', color: 'var(--site-dark-text-color)', backgroundColor: 'var(--site-primary-color)', marginTop: '5%'}} onClick={handleConfirm}>Good to go!</Button>
                </Box>
                {/* <div className="start-session">
                    <input id="begin-training" type="submit" value={'Good to go!'} onClick={handleConfirm} />
                </div> */}
            </> : <>
                <Box sx={{textAlign: 'center', height: '33px', width: '100%'}}>
                    <Button variant="contained" style={{width: '49%', fontSize: '12px', color: 'var(--site-dark-text-color)', backgroundColor: 'var(--site-primary-color)', margin: '5% 2% 0 0'}} onClick={handleConfirm}>Good to go!</Button>
                    <Button variant="contained" style={{width: '49%', fontSize: '12px', color: 'var(--site-dark-text-color)', backgroundColor: 'var(--site-primary-color)', marginTop: '5%'}} onClick={() => navigate('/')}>Miss this one out</Button>
                </Box>
                {/* <Box sx={{textAlign: 'center', height: '33px', width: '100%'}}>
                </Box> */}
                {/* <div className="start-session">
                    <input id="begin-training" type="submit" value={'Good to go!'} onClick={handleConfirm} />
                </div>
                <div className="start-session">
                    <input id="skip-training" type="submit" value={'Miss this one out'} onClick={() => navigate('/')} />
                </div> */}
            </>}  
        </div>
    </>)
};

export default MoodConf;