import { useEffect, useState } from "react";
import axios from "axios";
import { fetchAuthSession } from '@aws-amplify/auth';
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ResourceEntry from "./components/ResourceEntry";
import LoadingMessage from "../../components/LoadingMessage";
import Header from "../../components/general/Header.tsx";
import ErrorMessage from "../../components/ErrorMessage.js";
import ContentViewer from "../../components/general/ContentViewer.js";

const Resources = () => {

    const [files, setFiles] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState('all');
    const [seriesFilter, setSeriesFilter] = useState('all');
    const [isTokenReady, setIsTokenReady] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isViewing, setIsViewing] = useState(false);
    const [content, setContent] = useState({ url: "" });


    useEffect(() => {
        const fetchJwtToken = async () => {
          try {
            const session = await fetchAuthSession();
            const token = session.tokens.idToken;
            axios.defaults.headers['Authorization'] = `Bearer ${token}`;
            setIsTokenReady(true); 
          } catch (error) {
            console.error('Error fetching JWT token:', error);
            setIsError(true);
          }
        };
    
        fetchJwtToken();
      }, []);

    useEffect(() => {
        if (isTokenReady) {
            axios.get(process.env.REACT_APP_API_HOST + '/api/resources/general')
            .then(response => {
                const data = response.data;
                setFiles(data);
            })
            .catch(error => {
                console.error('Error fetching all skills:', error);
                setIsError(true);
            });
        }
    }, [isTokenReady])

    const openViewer = (url) => {
        setContent({
            url: url
        });
        setIsViewing(true);
    };

    if (isError) return <ErrorMessage handleConfirm={() => setIsError(false)} />;

    if (files?.length === 0) {
        return <LoadingMessage title={'Resources'} />
    };

    return (
    <>
        <div className="resource-links">
            <Header title={'Resources'} />
            
            <div style={{backgroundColor: 'var(--site-light-background-color)', padding: '10px', borderRadius: '10px'}}>
                
                <FormControl style={{marginTop: '10px', width: categoryFilter === 'competition' ? '49%' : '100%', marginRight: '2%'}}>
                    <InputLabel id="simple-select-label">Category</InputLabel>
                    <Select
                        labelId="simple-select-label"
                        id="demo-simple-select"
                        value={categoryFilter}
                        label="Category"
                        onChange={(e) => setCategoryFilter(e.target.value)}
                    >
                        <MenuItem value={'all'}>All</MenuItem>
                        <MenuItem value={'competition'}>Competition resources</MenuItem>
                        <MenuItem value={'training'}>Training resources</MenuItem>
                    </Select>
                </FormControl>
                {categoryFilter === 'competition' &&
                    <FormControl style={{marginTop: '10px', width: '49%'}}>
                        <InputLabel id="simple-select-label">Series</InputLabel>
                        <Select
                            labelId="simple-select-label"
                            id="demo-simple-select"
                            value={seriesFilter}
                            label="Series"
                            onChange={(e) => setSeriesFilter(e.target.value)}
                        >
                            <MenuItem value={'all'}>All</MenuItem>
                            <MenuItem value={'british'}>British</MenuItem>
                            <MenuItem value={'league'}>League</MenuItem>
                            <MenuItem value={'bucs'}>BUCS</MenuItem>
                            <MenuItem value={'neut'}>NEUT</MenuItem>
                        </Select>
                    </FormControl>
                }
            </div>
            <div style={{paddingLeft: '10px'}}>
                <div className='entries' style={{top: '165px'}}>
                    {files?.filter((item) => {
                        if (categoryFilter === 'all' || item.type === categoryFilter) {
                            return item;
                        } else {
                            return false;
                        }
                        }).filter((item) => {
                            if (seriesFilter === 'all' || item.series === seriesFilter) {
                                return item;
                            } else {
                                return false;
                            }
                        }).map((resource, index) => (
                            <ResourceEntry key={index} file={resource} handleOpen={openViewer} />
                        )
                    )} 
                </div>
            </div>
        </div>

        {isViewing && 
            <ContentViewer contentUrl={content.url} handleClose={() => setIsViewing(false)} />
        }
    </>)

};

export default Resources;