import { IconButton } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';

const progressColors = {
    red: '#FF0000',
    amber: '#FFBF00',
    green: '#008000',
};

const GoalReviewEntry = ({review, setEditing, remove}) => {
    
    const dotColor = progressColors[review.rag] || '#fff';

    const handleDeleteClick = (event) => {
        event.stopPropagation();
        remove(review.id);
    };

    return (
        <div className='entry' style={{cursor: 'pointer', position: 'relative', border: '1px solid rgba(0,0,0,0.3)', borderRadius: '10px', padding: '5px', margin: '5px 0'}} onClick={() => setEditing(review.id)}>
            
            <IconButton style={{position: 'absolute', top: '5px', right: '5px', padding: '0', fontSize: '20px'}} aria-label="delete" size="large" alt='delete' onClick={handleDeleteClick}>
                <DeleteIcon color="black" fontSize="inherit" />
            </IconButton>

            <h3 style={{ padding: '0 15px' }}>{new Date(review.review_date).toLocaleDateString('en-GB')}</h3>
            <p>{review.review}</p>
            {review.rag.length !== 0 &&
                <p>
                    Progress: <span style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: dotColor,
                }}></span>
                </p>
            }
        </div>
    )
}

export default GoalReviewEntry;