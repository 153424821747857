import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";

const PlaySkillFocus = ({playSkill, handleUpdate, handleExit}) => {

    const [attempts, setAttempts] = useState(playSkill.attempts);
    const [proficiency, setProficiency] = useState(playSkill.proficiency);

    const bgColor = () => {
        if (playSkill.proficiency === 0) {
            return "#D2222D";
        }
        else if (playSkill.proficiency === 1) {
            return "#FFBF00";
        }
        else {
            return "#238823";
        }
    }  

    return (
        <div style={{position: 'fixed', top: '0', left: '0', right: 0, height: 'calc(100% - 56px)', backgroundColor: 'rgba(84, 84, 84, 0.7)', maxWidth: '400px', margin: 'auto'}}>
           
            <div style={{ width: 'calc(100% - 40px)', position: 'absolute', top: '0', bottom: '0', margin:'auto 20px', height: 'fit-content'}}>
                <div style={{border: '3px solid black', borderRadius: '15px', backgroundColor: bgColor(), padding: '0 10px 10px'}}>
                    <h2 style={{color: '#000'}}>{playSkill.name}</h2>
                    <p style={{color: '#000', fontSize: '1.6rem'}}>Attempts:</p>
                    <div className="hydration-buttons" style={{margin: '10px 20% 10%'}}>                    
                        <Button variant="outlined" size="medium" className="add-subtract-button" onClick={() => setAttempts(attempts === 0 ? 0 : attempts - 1)}>-</Button>
                            <p style={{/*fontWeight: '1000',*/ color: '#000'}}>{attempts}</p>
                        <Button variant="outlined" size="large" className="add-subtract-button"  onClick={() => setAttempts(attempts + 1)}>+</Button>
                    </div>
                    <ToggleButtonGroup fullWidth
                        // style={{margin: '15px'}}
                        value={proficiency}
                        exclusive
                        onChange={(e) => setProficiency(parseInt(e.target.value))}
                        aria-label="Proficiency"
                    >
                        <ToggleButton style={{backgroundColor: '#fff', border: '1px solid var(--site-background-color)', fontSize: '10px'}} color="error" value={0} aria-label="meh">Meh</ToggleButton>
                        <ToggleButton style={{backgroundColor: '#fff', border: '1px solid var(--site-background-color)', fontSize: '10px'}} color="warning" value={1} aria-label="getting there">Getting there</ToggleButton>
                        <ToggleButton style={{backgroundColor: '#fff', border: '1px solid var(--site-background-color)', fontSize: '10px'}} color="primary" value={2} aria-label="got it">Got it!</ToggleButton>                
                    </ToggleButtonGroup>      
                </div>
                <div className='flex-buttons'>
                    <Button
                        style={{margin: "10px 5px 5px", width: '100px'}}
                        onClick={() => handleUpdate(attempts, proficiency)}
                        variant="contained"
                    >
                        Save
                    </Button>

                    <Button
                        style={{margin: "10px 5px 5px", width: '100px'}}
                        onClick={handleExit}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                </div> 

                {/* <Box sx={{textAlign: 'center', height: '33px', width: '100%'}}>
                    <Button variant="contained" style={{width: '49%', fontSize: '12px', color: 'var(--site-dark-text-color)', backgroundColor: 'var(--site-primary-color)', margin: '5% 2% 0 0'}} onClick={() => handleUpdate(attempts, proficiency)}>Log Progress</Button>
                    <Button variant="contained" style={{width: '49%', fontSize: '12px', color: 'var(--site-dark-text-color)', backgroundColor: 'var(--site-primary-color)', marginTop: '5%'}} onClick={handleExit}>Cancel</Button>
                </Box> */}

                {/* <div className="flex-buttons">
                    <div className="skill-bottom-button" style={{ backgroundColor: '#fff', width: '50%' }}>
                        <input id="log-progress" type="submit" value={'Log progress'} onClick={() => handleUpdate(attempts, proficiency)} style={{color: 'var(--site-dark-text-color)'}} />
                    </div>
                    <div className="skill-bottom-button" style={{ backgroundColor: '#fff', width: '50%' }}>
                        <input id="cancel" type="button" value={'Cancel'} onClick={handleExit} style={{color: 'var(--site-dark-text-color)'}} />
                    </div>
                </div> */}
            </div>
            
        </div>
    );
};

export default PlaySkillFocus;