import { createTheme } from '@mui/material/styles';

const customMuiTheme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--site-light-background-color)',
        },
        indicator: {
          height: '3px',
          backgroundColor: 'var(--site-primary-color)',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: 'var(--site-dark-text-color)',
          },
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          '& .MuiBottomNavigationAction-label': {
            color: '#545454',
            fontSize: '1.2rem',
          },
          '&.Mui-selected': {
            '& .MuiSvgIcon-root': {
              color: '#32a6a8',
            },
            '& .MuiBottomNavigationAction-label': {
              fontSize: '1.2rem',
            },
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--site-primary-color)',
          color: 'var(--site-background-color)', 
          '&:hover': {
            backgroundColor: 'var(--site-primary-color)', 
          },
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
          backgroundColor: 'var(--site-primary-color)',
          color: 'var(--site-dark-text-color)',
          textTransform: 'capitalize',
          transition: 'all 0.3s ease', // Smooth hover transition
        },
        contained: {
          '&:hover': {
            backgroundColor: '#32a6a8', // Hover color
          },
          '&:active': {
            backgroundColor: '#32a6a8', // Pressed color
            opacity: 0.85, // Optional: Slightly dim the color when pressed
          },
        },
        outlined: {
          '&:hover': {
            borderColor: '#32a6a8', // Hover border color for outlined buttons
            backgroundColor: 'rgba(0, 196, 255, 0.08)', // Slight hover background
          },
          '&:active': {
            borderColor: '#32a6a8', // Pressed border color
            backgroundColor: 'rgba(0, 196, 255, 0.16)', // Slightly darker pressed background
          },
        },
        text: {
          '&:hover': {
            color: '#00C4FF', // Hover text color
            backgroundColor: 'rgba(0, 196, 255, 0.08)', // Slight hover background
          },
          '&:active': {
            color: '#00C4FF', // Pressed text color
            backgroundColor: 'rgba(0, 196, 255, 0.16)', // Slightly darker pressed background
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          '.MuiDataGrid-cell': {
              fontSize: '1.2rem',
          },
        },
        columnHeaders: {
          color: '#000',
          fontWeight: 'bold',
          fontSize: '1.2rem',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#83FFE7', // Replace with your desired color
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#FFFFFF',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#83FFE7',
          },
          '&.Mui-focused': {
            backgroundColor: '#FFFFFF',
          },
          '&.Mui-disabled': {
            backgroundColor: '#F5F5F5',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#83FFE7',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            legend: {
              fontSize: '0.9rem', // Match the label's shrunk size to prevent clipping
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#6B7280', 
          fontSize: '1.2rem',
          '&.Mui-focused': {
            color: '#6B7280',
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff', // Default background color
          borderRadius: '8px',       // Optional: Rounded corners
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
          root: {
              fontSize: "1.1rem", // Adjust font size
              padding: "8px 16px", // Optional: Adjust padding
              color: "#545454",   // Optional: Default text color
          },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          fontSize: '1.3rem'
        },
        popper: {
          '& .MuiAutocomplete-listbox': {
              fontSize: '1.25rem',
          },
      },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          style: { fontSize: '16px' }, // Applies directly to input content
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
          root: {
              fontSize: '14px',
              padding: '4px 16px', 
          },
          head: {
              fontSize: '1.2rem',
              fontWeight: 'bold',
              backgroundColor: '#e0e0e0',

          },
      },
    },
    MuiTableContainer: {
        styleOverrides: {
            root: {
                backgroundColor: '#e0e0e0',
            },
        },
    },
    MuiMenuItem: {
      styleOverrides: {
          root: {
              fontSize: '1.4rem',
              padding: '12px 16px', // Adjust padding
              '&:hover': {
                  backgroundColor: '#f0f0f0', // Change hover color
              },
          },
      },
  },
  },
});

export default customMuiTheme;