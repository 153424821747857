import { Button, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";

const ViewCircuitExercises = ({title, exercises, exList, handleClose}) => {


    return (<>
        <div style={{position: 'fixed', top: '0', left: '0', right: 0, height: 'calc(100% - 56px)', backgroundColor: 'rgba(84, 84, 84, 0.7)'}}></div>
        <div style={{backgroundColor: '#e0e0e0', border: "2px solid #545454", borderRadius: '15px', position: 'absolute', top: '0', bottom: '10%', margin:'auto 0', height: 'fit-content', width: '100%', zIndex: '1000', padding: '10px'}}>
            <h2>{title}</h2>

            <TableContainer component={Paper} sx={{ marginTop: '20px', maxWidth: '400px', margin: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Exercise</TableCell>
                            <TableCell align="center">Reps</TableCell>
                            <TableCell align="center">Duration</TableCell>
                            <TableCell align="center">Weight</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {exercises?.map((cEx, index) => {
                            const exercise = exList.find(
                                ex => ex.id === cEx.exercise_id && ex.type === cEx.exercise_type
                            );
    
                            return (
                                <TableRow key={index}>
                                    <TableCell>{exercise ? exercise.name : "Unknown Exercise"}</TableCell>
                                    <TableCell align="center">{cEx.reps || '-'}</TableCell>
                                    <TableCell align="center">{cEx.duration ? `${cEx.duration} secs` : '-'}</TableCell>
                                    <TableCell align="center">{cEx.weight ? `${cEx.weight} kg` : '-'}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <div className="flex-buttons">
                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={handleClose}
                    variant="contained"
                >
                    Close
                </Button>
            </div>
            
        </div>
    </>)
}

export default ViewCircuitExercises;