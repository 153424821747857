import { useEffect, useState } from "react";
import axios from "axios";
import { Button, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper } from "@mui/material";

const ViewTestExercises = ({title, testId, exercises, handleClose}) => {

    const [testExList, setTestExList] = useState([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_HOST + `/api/conditioning/tests/exercises/${testId}`)
        .then(response => {
            const data = response.data;
            if (data !== null) {
                setTestExList(data);
            }
        })
        .catch(error => {
            console.error('Error fetching user conditioning tests:', error);
        });
    }, [testId])

    return (<>
        <div style={{position: 'fixed', top: '0', left: '0', right: 0, height: 'calc(100% - 56px)', backgroundColor: 'rgba(84, 84, 84, 0.7)'}}></div>
        <div style={{backgroundColor: '#e0e0e0', border: "2px solid #545454", borderRadius: '15px', position: 'absolute', top: '0', bottom: '10%', margin:'auto 0', height: 'fit-content', width: '100%', zIndex: '1000', padding: '10px'}}>
            <h2>{title}</h2>
            
            <TableContainer component={Paper} sx={{ marginTop: '20px', maxWidth: '400px', margin: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Exercise</TableCell>
                            <TableCell align="center">Metric</TableCell>
                            <TableCell align="center">Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {testExList?.map((cEx, index) => {
                            const exercise = exercises.find(
                                ex => ex.id === cEx.exercise_id && ex.type === cEx.exercise_type
                            );

                            return (
                                <TableRow key={index}>
                                    <TableCell>{exercise ? exercise.name : "Unknown Exercise"}</TableCell>
                                    <TableCell align="center">{cEx.metric}</TableCell>
                                    <TableCell align="center" style={{ maxWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word' }}>{cEx.details}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <div className="flex-buttons">
                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={handleClose}
                    variant="contained"
                >
                    Close
                </Button>
            </div>
            
        </div>
    </>)
}

export default ViewTestExercises;