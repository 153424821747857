import { LinearProgress, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#83ffe7', // Color for the progress bar
  },
  backgroundColor: '#c4c4c4', // Optional: Background color of the track
}));

const LinearProgressWithLabel = ({ progress }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ width: '50%' }}>
        <CustomLinearProgress variant="determinate" value={progress} />
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
