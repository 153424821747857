import { useState } from "react";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const DrillEntry = ({skillsList, drill, setEditing, duplicate, remove}) => {

    const [state, setState] = useState(false);

    var thisDrill = {
        ...drill,
        skills: drill.drill.skills.map((skill) => {
            return skillsList.filter((value) => value.id === skill)[0];
        })
    };

    return (
        <div className='entry' style={{position: 'relative', border: '2px solid var(--site-light-background-color)', borderRadius: '10px', padding: '5px', margin: '5px 0'}}>
            <h3 style={{ padding: '0 40px' }}>
                {drill.name}
                {/* <span style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        marginLeft: '10px',
                        borderRadius: '50%',
                        backgroundColor: drill.status === 'active' ? '#008000' : '#F50213',
                }}></span> */}
            </h3>
            <IconButton style={{position: 'absolute', top: '6px', right: '5px', padding: '0', fontSize: '20px'}} aria-label="delete" size="large" alt='delete' onClick={() => (remove(drill.drill.drill_id))}>
                <DeleteIcon color="black" fontSize="inherit" />
            </IconButton>
            <IconButton style={{position: 'absolute', top: '6px', right: '28px', padding: '0', fontSize: '20px'}} aria-label="edit" size="large" alt='edit' onClick={() => setEditing(drill)}>
                <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton style={{position: 'absolute', top: '40px', right: '5px', padding: '0', fontSize: '20px'}} aria-label="edit" size="large" alt='edit' onClick={() => duplicate(drill)}> 
                <ContentCopyIcon fontSize="inherit" />
            </IconButton>
            
            <div style={{ textAlign: 'center', maxWidth: '400px' }}>
                <p style={{ fontWeight: '600', margin: '10px 0' }}>
                    Length: <span style={{ fontWeight: 'bold' }}>{drill.drill.length}</span>, 
                    No. of repetitions: <span style={{ fontWeight: 'bold' }}>{drill.drill.repetitions}</span>
                </p>
                
                {state && (
                    <>
                        <ol style={{ margin: '0 auto', textAlign: 'start', display: 'inline-block' }}>
                            {thisDrill.skills.map((skill, index) => (
                            <li key={index} style={{ marginBottom: '5px' }}>
                                <p style={{ margin: '0', textAlign: 'start', fontWeight: '600' }}>
                                {index + 1}, {skill.name}
                                </p>
                            </li>
                            ))}
                        </ol>
                    </>
                )}
            </div>
            <Button style={{fontSize: '14px', color: '#000', backgroundColor: 'transparent'}} onClick={() => setState(!state)}>{!state ? 'Show more' : 'Show less'}</Button>

        </div>
    )

}

export default DrillEntry;