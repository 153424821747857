import { ToggleButton, ToggleButtonGroup, Button, IconButton } from "@mui/material";
import {useState, useEffect} from 'react';
import axios from "axios";
import { fetchAuthSession } from '@aws-amplify/auth';
import LogoutIcon from '@mui/icons-material/Logout';
import LoadingMessage from "../../components/LoadingMessage";
import Header from "../../components/general/Header.tsx";
import ErrorMessage from "../../components/ErrorMessage.js";


const Settings = ({userId, signOut}) => {

    const [hasChanged, setHasChanged] = useState(false);
    const [isTokenReady, setIsTokenReady] = useState(false);
    const [settings, setSettings] = useState(null);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
      const fetchJwtToken = async () => {
        try {
          const session = await fetchAuthSession();
          const token = session.tokens.idToken;
          axios.defaults.headers['Authorization'] = `Bearer ${token}`;
          setIsTokenReady(true); 
        } catch (error) {
          console.error('Error fetching JWT token:', error);
          setIsError(true);
        }
      };
  
      fetchJwtToken();
    }, []);
  
    useEffect(() => {
      if (isTokenReady) {
        axios.get(process.env.REACT_APP_API_HOST + `/api/user/${userId}`)
        .then(response => {
          const data = response.data;
          if (data !== null) {
            setSettings(data);
          }
        })
        .catch(error => {
          console.error('Error fetching user settings:', error);
          setIsError(true);
        })
      }
    }, [userId, isTokenReady]);

    const handleGenderChange = (event) => {
      setHasChanged(true);
      setSettings({
        ...settings,
        gender: event.target.value
      });
    };

    const handleSubmit = () => {
      axios.patch(process.env.REACT_APP_API_HOST + '/api/user', settings, {
        headers: {
            'Content-Type': 'application/json',
        },
        })
        .catch(error => {
            console.error('Error patching user details:', error);
            setIsError(true);
        }); 
    };

    const handleSignout = () => {
      localStorage.removeItem('jwtToken'); // Remove from localStorage
      axios.defaults.headers['Authorization'] = ''; // Clear  Axios header
      signOut();
    };    

    const manageSubscription = () => {
      axios.post(process.env.REACT_APP_API_HOST + `/payments/portal-session`)
      .then(response => {
        const data = response.data;
        if (data !== null) {
          window.location.href = response.data.url;
        }
      })
      .catch(error => {
        console.error('Error fetching user settings:', error);
        setIsError(true);
      })
    }

    if (isError) return <ErrorMessage handleConfirm={() => setIsError(false)} />;

    if (settings === null) {
      return (<>
        <LoadingMessage title={"Settings"} />
        <IconButton style={{position: 'absolute', top: '35px', right: '0', padding: '0', fontSize: "35px", color: "var(--site-light-text-color)"}} aria-label="delete" size="large" alt='delete' onClick={() => handleSignout()} >
          <LogoutIcon color="black" fontSize="inherit" />
        </IconButton>
      </>);
    }

    return (
        <>
          <Header title={'Settings'} />

          <IconButton style={{position: 'absolute', top: '35px', right: '0', padding: '0', fontSize: "35px", color: "var(--site-light-text-color)"}} aria-label="delete" size="large" alt='delete' onClick={() => handleSignout()} >
              <LogoutIcon fontSize="inherit" />
          </IconButton>

          <form className="new-entry" style={{padding: '5px'}}>

            <h4 style={{marginTop: "10px", textAlign: 'center'}}>What gender do you compete in?</h4>
            <ToggleButtonGroup
              fullWidth
              style={{margin: '10px 0 5px'}}
              color="primary"
              value={settings.gender}
              exclusive
              onChange={(e) => handleGenderChange(e)}
              aria-label="Active status"
            >
              <ToggleButton color="success" value={'male'} aria-label="male">Male</ToggleButton>
              <ToggleButton color="error" value={'female'} aria-label="female">Female</ToggleButton>
            </ToggleButtonGroup>

            <Button fullWidth onClick={manageSubscription}>Manage Subscription</Button>

            <div className='bottom-buttons'>
              <Button
                style={{margin: "10px 5px 5px", width: '100px'}}
                disabled={JSON.parse(settings.disciplines).length === 0 || !hasChanged}
                onClick={handleSubmit}
                variant="contained"
              >
                Save
              </Button>
            </div>

          </form>
        </>
    );

};

export default Settings;
