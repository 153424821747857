import { useState } from "react";
import { ToggleButton, ToggleButtonGroup, TextField, FormControl, InputLabel, Select, MenuItem, Button, Autocomplete } from "@mui/material";

const AddDmtResult = ({routines, resultHolder, handleNewResult, handleCancel}) => {

    // Initialise the result, be it blank or editing an existing one.
    const [result, setResult] = useState(resultHolder);

    const handleDateChange = (date) => {
        setResult({
            ...result,
            result_date: date
        });
    };

    const handleRoutineChange = (e, newValue) => {
        if (newValue !== null) {
            setResult({ 
                ...result, 
                pass: {id: newValue.id, name: newValue.name},
                difficulty: parseFloat(newValue.difficulty),
                total_score: parseFloat((result.execution + parseFloat(newValue.difficulty) - result.penalty).toFixed(3)) // move to submit?
            });
        }
        else {
            setResult({
                ...result,
                pass: {id: '', name: ''}
            });
        }
    };

    const handleFloatChange = (fieldName, e, min, max) => {
        let newVal = e.target.value === '' ? 0 : e.target.value;
        if (min !== 'none' && newVal < min) {
            newVal = min;
        } else if ( max !== 'none' && newVal > max) {
            newVal = max;
        }

        if (fieldName === 'total_score') {
            setResult((result) => ({ ...result, [fieldName]: parseFloat(newVal) }));
        }
        else {
            if (fieldName === 'penalty') {
                setResult((result) => (
                    { 
                        ...result, 
                        penalty: parseFloat(newVal),
                        total_score: parseFloat((result.execution + result.difficulty - parseFloat(newVal)).toFixed(3))
                    }
                ));
            }
            else {
                setResult((result) => (
                    { 
                        ...result, 
                        [fieldName]: parseFloat(newVal),
                        total_score: parseFloat((result.execution + result.difficulty - result.penalty - result[fieldName] + parseFloat(newVal)).toFixed(3))
                    }
                ));
            }
            
        }
    };

    // Handle changes to both feedback and self-reflection fields
    const handleFieldChange = (fieldName, e) => {
        if (fieldName === 'score_input_type') {
            setResult({
                ...result,
                execution: 0,
                penalty: 0
            });
        }

        if (e.target.value === '') {
            setResult((result) => ({ ...result, [fieldName]: null }));
        } else {
            setResult((result) => ({ ...result, [fieldName]: e.target.value }));
        }
    };

    const compareRoutine = (routine_id) => {
        let res = routines.filter((item) => item.id === routine_id)[0];
        return res !== undefined ? res : {
            id: '',
            name: ''
        };
    };

    const inputStyle = {
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center', // Align text vertically in the center
        textAlign: 'center',
    };

    return (
        <form className="new-entry">

            {/* Name of event */}
            <TextField fullWidth style={{ marginTop: "10px" }}
                type="text"
                id="event-name"
                label="Event Name *"
                variant="outlined"
                value={result.event !== null ? result.event : ''}
                onChange={(e) => handleFieldChange('event', e)}
            />

            {/* Date of result */}
            <TextField fullWidth
                type="date"
                id="result-date"
                label="Result Date"
                value={result.result_date.split('T')[0]}
                onChange={(e) => {handleDateChange(new Date(e.target.value).toISOString())}}
                InputLabelProps={{ shrink: true }}                
                style={{ marginTop: '10px' }} 
                InputProps={{ style: inputStyle }}                 
            />

            {/* Routine dropdown */} 
            <Autocomplete style={{marginTop: '10px'}} 
                options={routines.filter((item) => (item.discipline === 'dmt'))} 
                getOptionLabel={(option) => option.name} 
                isOptionEqualToValue={() => true}
                value={compareRoutine(result.pass.id)}
                onChange={handleRoutineChange}
                renderInput={(params) => (
                    <TextField {...params} label={"Search or select a routine (optional)"} variant="outlined" />
                )
            }/>

            {/* Total score or constituent parts */}
            <ToggleButtonGroup
                fullWidth
                style={{marginTop: '10px'}}
                color="primary"
                value={result.score_input_type}
                exclusive
                onChange={(e) => handleFieldChange('score_input_type', e)}
                aria-label="Total score or parts"
            >
                <ToggleButton color="primary" value={'parts'} aria-label="parts">Score Parts</ToggleButton>
                <ToggleButton color="primary" value={'total'} aria-label="total">Total Score</ToggleButton>
            </ToggleButtonGroup>

            { result.score_input_type === 'parts' && <>
                {/* Execution number textbox */} 
                <TextField style={{ width: "49.5%", margin: "10px 1% 0 0" }}
                    type="number"
                    id="execution"
                    label="Execution"
                    variant="outlined"
                    value={result.execution > 0 ? result.execution : ''}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => handleFloatChange('execution', e, 0, 20)}
                />

                {/* Difficulty number textbox */}
                <TextField style={{ width: "49.5%", marginTop: "10px" }}
                    type="number"
                    id="difficulty"
                    label="Difficulty"
                    variant="outlined"
                    value={result.difficulty > 0 ? result.difficulty : ''}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => handleFloatChange('difficulty', e, 0, 'none')}
                />

                {/* Penalty number textbox */} 
                <TextField style={{ width: "49.5%", margin: "10px 1% 0 0" }}
                    type="number"
                    id="penalty"
                    label="Penalty"
                    variant="outlined"
                    value={result.penalty}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => handleFloatChange('penalty', e, 0, 'none')}
                />

                {/* Number of completed skills */}
                <FormControl style={{ width: "49.5%", marginTop: "10px" }}>
                    <InputLabel id="completed-skills-label">Skills Completed</InputLabel>
                    <Select
                        labelId="completed-skills-label"
                        label="Skills Completed"
                        variant="outlined"
                        value={result.skills_completed}
                        onChange={(e) => handleFieldChange('skills_completed', e)}
                        style={{fontSize: '16px'}}
                    >
                        {[...Array(2).keys()].map((number) => (
                            <MenuItem key={number + 1} value={number + 1}>{number + 1}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <p style={{ marginTop: "5px" }}>{'Total Score: ' + result.total_score}</p>
            </>}

            { result.score_input_type === 'total' && <>
                {/* Total score number textbox */} 
                <TextField fullWidth style={{ marginTop: "10px" }}
                    type="number"
                    id="total-score"
                    label="Total Score"
                    variant="outlined"
                    value={result.total_score}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => handleFloatChange('total_score', e, 0, 'none')}
                />
            </>}

            {/* Comments textbox */}  
            <TextField fullWidth style={{ marginTop: "10px" }}
                type="text"
                multiline
                rows={4}
                id="comments"
                label="Comments"
                variant="outlined"
                value={result.comment !== null ? result.comment : ''}
                onChange={(e) => handleFieldChange('comment', e)}
            />

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    disabled={result.event === null || result.event.length === 0}
                    onClick={() => handleNewResult('dmt', result)}
                    variant="contained"
                >
                    Save result
                </Button>

                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>           
        </form>
    )
};

export default AddDmtResult;