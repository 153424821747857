import { useState } from "react";
import { TextField, InputLabel, MenuItem, Button, Select, FormControl /*, styled*/ } from "@mui/material";
import Header from "../../../components/general/Header.tsx";


// const ragColors = {
//     red: 'rgba(255, 0, 0, 0.3)', // Light translucent red
//     amber: 'rgba(255, 223, 0, 0.3)', // Light translucent amber/orange
//     green: 'rgba(0, 128, 0, 0.3)' // Light translucent green
// };

// const StyledSelect = styled(Select)(({ value }) => ({
//     backgroundColor: value ? ragColors[value] : 'transparent',
// }));

const AddGoal = ({goalHolder, handleSubmit, handleCancel}) => {

    // Initialise the goal, be it blank or editing an existing one.
    const [goal, setGoal] = useState(goalHolder);

    // Handle changes to both feedback and self-reflection fields
    const handleFieldChange = (fieldName, e) => {
        if (e.target.value === '') {
            setGoal((goal) => ({ ...goal, [fieldName]: '' }));
        } else {
            setGoal((goal) => ({ ...goal, [fieldName]: e.target.value }));
        }
    };

    const handleDateChange = (field, date) => {
        setGoal({
            ...goal,
            [field]: date
        });
    };

    const preSubmit = () => {

        handleSubmit({...goal, original_target_date: goal.target_date})
    };

    return (<>
        <Header title={'Add Goal'} />
        <form className="new-entry">

            {/* goal title */}
            <TextField fullWidth
                type="text"
                id="title"
                label="Brief Title"
                variant="outlined"
                value={goal.title || ''}
                onChange={(e) => handleFieldChange('title', e)}
            />

            {/* goal description */}
            <TextField fullWidth style={{ marginTop: "10px" }}
                type="text"
                id="description"
                multiline
                rows={5}
                label="Detailed description"
                variant="outlined"
                value={goal.description || ''}
                onChange={(e) => handleFieldChange('description', e)}
            />


            <FormControl style={{width: '49.5%', marginRight: '1%', marginTop: '15px'}}>
                <InputLabel>Goal Type</InputLabel>
                <Select
                    value={goal.goal_type || ''}
                    onChange={(e) => handleFieldChange('goal_type', e)}
                    variant="outlined"
                    style={{fontSize: '16px'}}
                >
                    <MenuItem value="performance">Performance</MenuItem>
                    <MenuItem value="outcome">Outcome</MenuItem>
                    <MenuItem value="process">Process</MenuItem>
                </Select>
            </FormControl>


            {/* <FormControl style={{width: '49.5%', marginTop: '10px'}}>
                <InputLabel>Status</InputLabel>
                <Select
                    value={goal.status || ''}
                    onChange={(e) => handleFieldChange('status', e)}
                >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="in progress">In Progress</MenuItem>
                    <MenuItem value="complete">Complete</MenuItem>
                    <MenuItem value="overdue">Overdue</MenuItem>
                </Select>
            </FormControl> */}

            <TextField style={{width: '49.5%', /*, marginRight: '1%',*/ marginTop: '15px'}}
                label="Start Date"
                type="date"
                value={goal.start_date.split('T')[0] || null}
                onChange={(e) => handleDateChange('start_date', new Date(e.target.value).toISOString())}
                InputLabelProps={{ shrink: true }}
            />
            <TextField style={{width: '49.5%', marginRight: '1%', marginTop: '15px'}}
                label="Target Date"
                type="date"
                value={goal.target_date.split('T')[0] || null}
                onChange={(e) => handleDateChange('target_date', new Date(e.target.value).toISOString())}
                InputLabelProps={{ shrink: true }}
            />

            <TextField style={{width: '49.5%', marginTop: '15px'}}
                label="Next Review Date"
                type="date"
                value={goal.next_review_date.split('T')[0] || null}
                onChange={(e) => handleDateChange('next_review_date', new Date(e.target.value).toISOString())}
                InputLabelProps={{ shrink: true }}
            />

            {/* <FormControl style={{ width: '49.5%', marginTop: '15px' }} >
                <InputLabel id="progress-label">Progress (RAG)*</InputLabel>
                <StyledSelect
                    labelId="progress-label"
                    label="Progress (RAG)"
                    variant="outlined"
                    value={goal.rag || ""}
                    onChange={(e) => handleFieldChange('rag', e)}
                >
                    <MenuItem value={""}></MenuItem>
                    <MenuItem value={"red"} style={{ backgroundColor: ragColors.red }}>Red</MenuItem>
                    <MenuItem value={"amber"} style={{ backgroundColor: ragColors.amber }}>Amber</MenuItem>
                    <MenuItem value={"green"} style={{ backgroundColor: ragColors.green }}>Green</MenuItem>
                </StyledSelect>
            </FormControl>   */}
            

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    disabled={goal.title === null || goal.title.length === 0
                        || goal.description === null || goal.description.length === 0}
                    onClick={preSubmit}
                    variant="contained"
                >
                    Save goal
                </Button>

                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>           
        </form>
        </>)

};

export default AddGoal